<template>
  <v-container fluid class="pt-0">
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="0" class="fill-height" min-height="280">
          <v-card-text class="pa-0">
            <v-tabs class="custom-tabs" color="success" v-model="tab">
              <v-tab class="text-left justify-start" v-for="(page, idx) in pages" :key="idx">
                {{ getPageTranslate(page) }}
              </v-tab>

              <v-tab-item class="pa-0" v-for="(page, idx) in pages" :key="idx">
                <v-row>
                  <v-col cols="12">
                    <v-card tile elevation="2" class="fill-height pa-3" style="border-left: 3px solid #5bb55f"
                            min-height="280">
                      <v-row>
                        <template v-for="(item, index) in getPageKeys(page)">
                          <template v-if="item.type === 'id'">
                            <v-col cols="12" md="6" :key="`left-${index}`">
                              <v-select
                                  :label="item.print_name"
                                  :items.sync="getItemsForSelect[item.table_name]"
                                  v-model="item.value"
                                  hide-details
                                  filled
                              />
                            </v-col>
                            <v-col cols="12" md="6" :key="`right-${index}`">
                              <div class="notification-description">
                                <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                {{ item.description }}
                              </div>
                            </v-col>
                          </template>
                          <template v-if="item.type === 'string' && item.client_func">
                            <v-col cols="12" md="6" :key="`left-${index}`">
                              <v-select
                                  :label="item.print_name"
                                  :items.sync="getItemsForSelect[item.client_func]"
                                  v-model="item.value"
                                  hide-details
                                  filled
                              />
                            </v-col>
                            <v-col cols="12" md="6" :key="`right-${index}`">
                              <div class="notification-description">
                                <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                {{ item.description }}
                              </div>
                            </v-col>
                          </template>
                          <template v-if="item.type === 'bool'">
                            <v-col cols="12" md="6" :key="`left-${index}`">
                              <v-switch
                                  v-model="item.value"
                                  hide-details
                                  :label="item.value ? `${item.print_name} (включено)` : `${item.print_name} (відключено)`"
                                  :disabled="getRelatedValue(item.related_name)"
                                  color="success"
                              >
                              </v-switch>
                            </v-col>
                            <v-col cols="12" md="6" :key="`right-${index}`">
                              <div class="notification-description">
                                <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                {{ item.description }}
                              </div>
                            </v-col>
                          </template>
                        </template>
                        <v-col cols="6">
                          <v-btn block depressed color="grey lighten-2" @click="saveSettings">
                            Зберегти
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {SELECT_CHART_OF_ACCOUNT} from "@/store/actions/accounting/chart_of_account";
import organizationAPI from "@/utils/axios/organization"
import {ALERT_SHOW} from "@/store/actions/alert";
import {GET_ACCOUNTING_GENERAL_SETTINGS} from "@/store/actions/organization";
import {
  SELECT_CURRENCY_LIST_ELEMENTS, SELECT_PERSONAL_LIST_ELEMENTS, SELECT_SUBDIVISION_LIST_ELEMENTS,
  SELECT_TAXES_LIST_ELEMENTS,
  SELECT_WAREHOUSE_LIST_ELEMENTS
} from "@/store/actions/accounting/list";
import {
  bank_document_income_operation,
  bank_document_outcome_operation,
  cash_order_document_outcome_operation,
  cash_order_document_income_operation, warehouse_outcome_type
} from "@/utils/icons"

export default {
  name: "AccountingGeneralSettings",
  components: {},
  data() {
    return {
      settings: {},
      selects_items: {},
      pages: [],
      tab: 0,
      modal_id: 0
    }
  },
  computed: {
    getItemsForSelect() {
      return this.selects_items
    },
    baseGeneral() {
      return this.settings?.bases_general?.value || false
    }
  },
  methods: {
    openModal(row) {
      this.modal_id = row.id
    },
    UpdateItemModal() {
      this.modal_id = 0
    },
    getPageTranslate(page) {
      const pages = {
        'general': 'Загальні',
        'bank': 'Банк',
        'calc': 'Покупці/Постач.',
        'pdv': 'ПДВ',
        'legacy': 'Юридичний облік'
      }
      return pages[page] || 'Інші'
    },
    getPageKeys(page) {
      const page_keys = []
      Object.keys(this.settings).forEach(item => {
        const value = this.settings[item]
        if (value.page === page) {
          page_keys.push(value)
        }
      })
      return page_keys
    },
    getItems(payload) {
      if (payload.type === 'id') {
        if (payload.table_name === 'accounting_codes') {
          if (this.selects_items.accounting_codes) {
            return
          }
          this.$store.dispatch(SELECT_CHART_OF_ACCOUNT)
              .then(res => {
                if (res) {
                  this.$set(
                      this.selects_items, 'accounting_codes',
                      this.$store.state.chart_of_account.chart_of_account_select
                  )
                }
              })
        }
        if (payload.table_name === 'warehouse') {
          if (this.selects_items.warehouse) {
            return
          }
          this.$store.dispatch(SELECT_WAREHOUSE_LIST_ELEMENTS)
              .then(res => {
                if (res) {
                  this.$set(
                      this.selects_items, 'warehouse',
                      this.$store.state.list.warehouse_list_elements_select
                  )
                }
              })
        }
        if (payload.table_name === 'currency') {
          if (this.selects_items.currency) {
            return
          }
          this.$store.dispatch(SELECT_CURRENCY_LIST_ELEMENTS)
              .then(res => {
                if (res) {
                  this.$set(
                      this.selects_items, 'currency',
                      this.$store.state.list.currency_list_elements_select
                  )
                }
              })
        }
        if (payload.table_name === 'subdivision') {
          if (this.selects_items.subdivision) {
            return
          }
          this.$store.dispatch(SELECT_SUBDIVISION_LIST_ELEMENTS)
              .then(res => {
                if (res) {
                  this.$set(
                      this.selects_items, 'subdivision',
                      this.$store.state.list.subdivision_list_elements_select
                  )
                }
              })
        }
        if (payload.table_name === 'taxes') {
          if (this.selects_items.taxes) {
            return
          }
          this.$store.dispatch(SELECT_TAXES_LIST_ELEMENTS)
              .then(res => {
                if (res) {
                  this.$set(
                      this.selects_items, 'taxes',
                      this.$store.state.list.taxes_list_elements_select
                  )
                }
              })
        }
        if (payload.table_name === 'personal') {
          if (this.selects_items.taxes) {
            return
          }
          this.$store.dispatch(SELECT_PERSONAL_LIST_ELEMENTS)
              .then(res => {
                if (res) {
                  this.$set(
                      this.selects_items, 'personal',
                      this.$store.state.list.personal_list_elements_select
                  )
                }
              })
        }
      }

      if (payload.client_func) {
        if (this.selects_items[payload.client_func]) {
          return
        }

        if (payload.client_func === 'bank_income_operation_type') {
          this.$set(this.selects_items, payload.client_func, bank_document_income_operation)
        }
        if (payload.client_func === 'bank_outcome_operation_type') {
          this.$set(this.selects_items, payload.client_func, bank_document_outcome_operation)
        }
        if (payload.client_func === 'cash_order_outcome_operation_type') {
          this.$set(this.selects_items, payload.client_func, cash_order_document_outcome_operation)
        }
        if (payload.client_func === 'cash_order_income_operation_type') {
          this.$set(this.selects_items, payload.client_func, cash_order_document_income_operation)
        }
        if (payload.client_func === 'default_warehouse_outcome_type_func') {
          this.$set(this.selects_items, payload.client_func, warehouse_outcome_type)
        }
        //
        // if (payload.client_func === 'split_type_select') {
        //   this.$set(this.selects_items, payload.client_func, split_type_select)
        // }
      }
    },
    getRelatedValue(col) {
      if (!col) {
        return false
      }
      return !this.settings[col]['value']
    },
    saveSettings() {
      organizationAPI.save_accounting_general_settings(this.settings)
          .then(res => {
            if (res) {
              this.$store.dispatch(ALERT_SHOW, {text: 'Налаштування збережені успіщно', color: 'success'})
              this.$store.dispatch(GET_ACCOUNTING_GENERAL_SETTINGS)
              this.$router.push({name: 'AccountingDesktopView', params: {page_name: 'admin'}})
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    }
  },
  created() {
    organizationAPI.get_accounting_general_settings()
        .then(response => response.data)
        .then(data => {
          this.settings = data

          Object.keys(this.settings).forEach(item => {
            const value = this.settings[item]
            this.getItems(value)
            if (!this.pages.includes(value.page)) {
              this.pages.push(value.page)
            }
          })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
  }
}
</script>

<style scoped lang="scss">
.notification-description {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f9fff6;
  height: 100%;
  border: 2px solid #e0f2d6;
  position: relative;
  color: #2d2d2d;
}

.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
</style>
